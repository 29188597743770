import React, { useMemo } from 'react';
import styled, {css} from 'styled-components';

import SbEditable from 'components/SbEditableExtended';
import StoryblokClient from 'storyblok-js-client';

import ContentWrapper from 'components/contentWrapper';

import config from 'settings/config';
import theme, {bp} from 'settings/theme';

import calculateBottomSpacingClass from 'components/blocks/utils/calculateBottomSpacingClass';



let Storyblok = new StoryblokClient({
  accessToken: config.storyBlok.publicToken
})


// Component
const Component = (props) => {

  const spacingBottomClass = useMemo(() => calculateBottomSpacingClass(props.block?.bottom_spacing), [props.block?.bottom_spacing]);

  if(!props.block){
    return(<div>Storyblok block missing</div>);
  }

	return(
    <SbEditable content={props.block}>
      <StyledComponent className={`blockRichText content spacingTop ${spacingBottomClass}`}>
          
        {props.block.content != "" &&
          <ContentWrapper>
            <div className="content" dangerouslySetInnerHTML={{__html: Storyblok.richTextResolver.render(props.block.content)}} />
          </ContentWrapper>
        }
        
      </StyledComponent>
    </SbEditable>
	)

}

export default Component;


// Styles
const StyledComponent = styled.div``;